// eslint-disable-next-line import/no-anonymous-default-export
export default {
    voteUrl: 'https://vote.pikkle.me',
    api: process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://adminapi.pikkle.me' : 'https://dev.adminapi.pikkle.me',
    apiCommon: process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://api.pikkle.me' : 'https://dev.api.pikkle.me',
    pikkUrl:
        process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://vote.pikkle.me/pikkle/common/vote/' : 'https://dev.admin.pikkle.me/pikkle/common/vote/',
    pikkPrivateUrl:
        process.env.REACT_APP_ENVIRONMENT === 'production'
            ? 'https://vote.pikkle.me/pikkle/common/p/vote/'
            : 'https://dev.admin.pikkle.me/pikkle/common/p/vote/',
    pikkDynamicUrl: process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://pikkle.page.link/' : 'https://pikklelink.page.link/',
    title: 'PIKKLE',
    defaultPath: [
        { role: 'SUPER_ADMIN', url: '/user/list' },
        { role: 'GROUP_MANAGER', url: '/dashboard/basic' },
        { role: 'MANAGER', url: '/dashboard/basic' },
    ],
    basename: '/pikkle', // only at build time to set, like /datta-able
    layout: 'vertical', // vertical, horizontal (not available in lite version)
    preLayout: null, // (not available in lite version)
    collapseMenu: false, // mini-menu
    layoutType: 'menu-dark', // menu-dark, (menu-light, dark are not available in lite version)
    navIconColor: false,
    headerBackColor: 'header-default', // header-default, (header-blue, header-red, header-purple, header-lightblue, header-dark are not available in lite version)
    navBackColor: 'navbar-default', // navbar-default, (navbar-blue, navbar-red, navbar-purple, navbar-lightblue, navbar-dark are not available in lite version)
    navBrandColor: 'brand-default', // brand-default, (brand-blue, brand-red, brand-purple, brand-lightblue, brand-dark are not available in lite version)
    navBackImage: false, // not available in lite version
    rtlLayout: false, // not available in lite version
    navFixedLayout: true,
    headerFixedLayout: false, // not available in lite version
    boxLayout: false,
    navDropdownIcon: 'style1', // style1, (style2, style3 are not available in lite version)
    navListIcon: 'style1', // style1, (style2, style3, style4, style5, style6 are not available in lite version)
    navActiveListColor: 'active-default', // active-default, (active-blue, active-red, active-purple, active-lightblue, active-dark are not available in lite version)
    navListTitleColor: 'title-default', // title-default, (title-blue, title-red, title-purple, title-lightblue, title-dark are not available in lite version)
    navListTitleHide: false, // not available in lite version
    configBlock: false, // not available in lite version
    layout6Background: 'linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)', // used only for pre-layout = layout-6
    layout6BackSize: '', // used only for pre-layout = layout-6
    accordionKey: 1,
    firebaseConfig:
        process.env.REACT_APP_ENVIRONMENT === 'production'
            ? {
                  apiKey: 'AIzaSyAG3wT0J0HN4JZbT-bZ1Wtt2FEBR2XMBEw',
                  authDomain: 'pikkle-a.firebaseapp.com',
                  projectId: 'pikkle-a',
                  storageBucket: 'pikkle-a.appspot.com',
                  messagingSenderId: '616384984916',
                  appId: '1:616384984916:web:f3856a2bd428958dafbac1',
                  measurementId: 'G-PKCL1NWSR8',
              }
            : {
                  apiKey: 'AIzaSyB8OC6spxFrJ8Fa6qqV-RBMmstaYqIQgdI',
                  authDomain: 'pikkle-admin-dev-928df.firebaseapp.com',
                  projectId: 'pikkle-admin-dev-928df',
                  storageBucket: 'pikkle-admin-dev-928df.appspot.com',
                  messagingSenderId: '687170326895',
                  appId: '1:687170326895:web:4533f1bf367906d811989a',
                  measurementId: 'G-MXLPZKV7S0',
              },
};
