import React, { Component, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';

import '../../node_modules/font-awesome/scss/font-awesome.scss';

import Loader from './layout/Loader';
import Aux from '../hoc/_Aux';
import ScrollToTop from './layout/ScrollToTop';
import routes from '../route';

import firebase from 'firebase/app';
import 'firebase/auth';
import config from '../config';

const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader,
});

const JWT_EXPIRY_TIME = 60 * 30 * 1000;

class App extends Component {
    constructor(props) {
        super(props);

        // this.refreshToken = this.refreshToken.bind(this);
        this.refreshToken();
        // setTimeout(this.refreshToken, JWT_EXPIRY_TIME);

        if (window.location.href === config.voteUrl || window.location.href === config.voteUrl + '/') {
            window.location.href = 'https://www.pikkle.me';
        }
    }

    refreshToken() {
        if (!firebase.apps.length) {
            firebase.initializeApp(config.firebaseConfig);
        }

        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                firebase
                    .auth()
                    .currentUser.getIdToken(true)
                    .then(idToken => {
                        localStorage.setItem('access_token', idToken);
                    })
                    .catch(error => {});
            } else {
                localStorage.setItem('access_token', '');
            }
        });

        setTimeout(this.refreshToken, JWT_EXPIRY_TIME);
    }

    //

    render() {
        let auth = localStorage.getItem('access_token');
        let role = localStorage.getItem('role');
        if (auth === '' || role === undefined || role === '') {
            auth = null;
        }
        // localStorage.setItem("role", "");

        let menu = routes.map((route, index) => {
            return route.component ? (
                <Route key={index} path={route.path} exact={route.exact} name={route.name} render={props => <route.component {...props} />} />
            ) : null;
        });

        return (
            <Aux>
                <ScrollToTop>
                    <Suspense fallback={<Loader />}>
                        {/* <Switch>
                            {auth === null || role === null ? menu : ""}
                            {auth === null || role === null ? <Redirect from="/" to="/auth/signin" /> : <Route path="/" component={AdminLayout} />}                            
                        </Switch> */}
                        <Switch>
                            {menu}
                            {auth === null || role === null ? <Redirect from="/" to="/auth/signin" /> : <Route path="/" component={AdminLayout} />}
                        </Switch>
                    </Suspense>
                </ScrollToTop>
            </Aux>
        );
    }
}

export default App;
