import * as actionTypes from "./actions";
import config from "./../config";

let profile = localStorage.getItem("profile");
if (profile === null || profile === undefined || profile === "") {
    profile = {};
} else {
    profile = JSON.parse(profile);
}

const initialState = {
    isOpen: [], //for active default menu
    isTrigger: [], //for active default menu, set blank for horizontal
    ...config,
    isFullScreen: false, // static can't change

    auth: "",
    profile: profile
};

// localStorage.setItem("profile", JSON.stringify(action.profile));
// alert(JSON.stringify(localStorage.getItem("profile")));

export const setAuth = val => ({ type: actionTypes.AUTH_INFO, payload: val });
export const setProfile = val => ({ type: actionTypes.PROFILE, profile: val });

const reducer = (state = initialState, action) => {
    let trigger = [];
    let open = [];

    switch (action.type) {
        case actionTypes.COLLAPSE_MENU:
            return {
                ...state,
                // collapseMenu: !state.collapseMenu
                collapseMenu: state.collapseMenu
            };
        case actionTypes.COLLAPSE_TOGGLE:
            if (action.menu.type === "sub") {
                open = state.isOpen;
                trigger = state.isTrigger;

                const triggerIndex = trigger.indexOf(action.menu.id);
                if (triggerIndex > -1) {
                    open = open.filter(item => item !== action.menu.id);
                    trigger = trigger.filter(item => item !== action.menu.id);
                }

                if (triggerIndex === -1) {
                    open = [...open, action.menu.id];
                    trigger = [...trigger, action.menu.id];
                }
            } else {
                open = state.isOpen;
                const triggerIndex = state.isTrigger.indexOf(action.menu.id);
                trigger = triggerIndex === -1 ? [action.menu.id] : [];
                open = triggerIndex === -1 ? [action.menu.id] : [];
            }

            return {
                ...state,
                isOpen: open,
                isTrigger: trigger
            };
        case actionTypes.NAV_CONTENT_LEAVE:
            return {
                ...state,
                isOpen: open,
                isTrigger: trigger
            };
        case actionTypes.NAV_COLLAPSE_LEAVE:
            if (action.menu.type === "sub") {
                open = state.isOpen;
                trigger = state.isTrigger;

                const triggerIndex = trigger.indexOf(action.menu.id);
                if (triggerIndex > -1) {
                    open = open.filter(item => item !== action.menu.id);
                    trigger = trigger.filter(item => item !== action.menu.id);
                }
                return {
                    ...state,
                    isOpen: open,
                    isTrigger: trigger
                };
            }
            return { ...state };
        case actionTypes.FULL_SCREEN:
            return {
                ...state,
                isFullScreen: !state.isFullScreen
            };
        case actionTypes.FULL_SCREEN_EXIT:
            return {
                ...state,
                isFullScreen: false
            };
        case actionTypes.CHANGE_LAYOUT:
            return {
                ...state,
                layout: action.layout
            };
        case actionTypes.AUTH_INFO:
            localStorage.setItem("auth", action.payload);
            // state = { auth: action.payload };
            return {
                ...state,
                auth: action.payload
            };
        case actionTypes.PROFILE:
            localStorage.setItem("profile", JSON.stringify(action.profile));
            // localStorage.setItem("auth", action.profile);
            // state = { profile: action.profile };
            console.log("action.profile", action.profile);
            return {
                ...state,
                profile: action.profile
            };
        default:
            return state;
    }
};

export default reducer;
