import moment from "moment";
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const Aux = props => props.children;

export default Aux;

export const addComma = number => {
    if (number === null || number === undefined) return "";
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

//이메일 형식 체크
export function isEmail(asValue) {
    var regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    return regExp.test(asValue); // 형식에 맞는 경우 true 리턴
}

// 전화번호 형식 체크
export function isPhoneNumber(asValue) {
    var regExp = /^01([0|1|6|7|8|9]?)?([0-9]{3,4})?([0-9]{4})$/
    return regExp.test(asValue); // 형식에 맞는 경우 true 리턴
}


export const searchTypeValue = (array, idx) => {
    if (idx === null) return "null";

    let t = array.filter(x => x.idx === idx);
    if (t === undefined) return "값 없음";

    if (t.length > 0) return t[0];
    return array[0];
};

// 콤마 넣기
export function NumberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

// 분에 의한 남은 시간 계산
export function getRemainTimeInMin(_min, _format) {
    let _fm = _min > 60*24 ? 'd[d] h:mm:ss' : 'h:mm:ss';
    if (_format !== '' && _format !== undefined) {
        _fm = _format;
    }
    return moment.duration(_min, "minute").format(_fm, {
        trim: false
    });
}

export function isNotEmptyArray(array) {
    return Array.isArray(array) && array.length >= 1
}

