import React from 'react';

const SignUp = React.lazy(() => import('./View/Auth/SignUp'));
const Signin = React.lazy(() => import('./View/Auth/SignIn'));
const ResetPwd = React.lazy(() => import('./View/Auth/ResetPwd'));

const AuthConfirm = React.lazy(() => import('./View/Common/AuthConfirm'));
const VotePC = React.lazy(() => import('./View/Common/VotePC'));
const VoteTemp = React.lazy(() => import('./View/Common/VoteTemp'));
const PrivateVote = React.lazy(() => import('./View/Common/PrivateVote'));
const Policy = React.lazy(() => import('./View/Common/Policy'));
const Store = React.lazy(() => import('./View/Common/GoStore'));

const route = [
    { path: '/auth/signup', exact: true, name: 'Signup', component: SignUp },
    { path: '/auth/signin', exact: true, name: 'Signin', component: Signin },
    { path: '/auth/resetpwd', exact: true, name: 'ResetPwd', component: ResetPwd },
    { path: '/common/p/vote/:id', exact: true, name: 'PrivateVote', component: PrivateVote },
    { path: '/common/vote/:id', exact: true, name: 'VotePC', component: VotePC },
    { path: '/common/vote/:id/:pikk', exact: true, name: 'VotePC', component: VotePC },
    { path: '/common/vote', exact: true, name: 'VoteTemp', component: VoteTemp },
    { path: '/common/policy/:type', exact: true, name: 'Policy', component: Policy },
    { path: '/common/admin/active', exact: true, name: 'AuthConfirm', component: AuthConfirm },
    { path: '/common/store', exact: true, name: 'Store', component: Store },
];

export default route;
